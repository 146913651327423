import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Tabs, message, Drawer, Result, Button } from "antd";
import style from "./style.module.css";
import { PhoneFilled } from "@ant-design/icons";
// import * as typeAction from '../../../store/constants/constants'
import {
  CustomerServiceOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import { useFormik } from "formik";
// import { traCuuSchema } from "../../../schemas/traCuuSchema";
import logo from "../../assets/images/logo/logo_ivy.png";
import axios from "axios";
// Import the main component
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import { useDispatch, useSelector } from "react-redux";
// import { traCuuKhamDoanAction } from "../../../store/actions/khamDoanAction";

const Home = () => {
  const [open, setOpen] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [infoTraCuu, setInfoUser] = useState(null);
  // const dispacth = useDispatch()
  // const { infoTraCuu } = useSelector(state => state.khamDoanReducer)
  const newPlugin = defaultLayoutPlugin();
  const handleSubmit = async (value) => {
    try {
      if (value.maBN.length !== 14) {
        error();
        return;
      }
      //       const result = await axios.get(`
      // https://api.315healthcare.com/api/Search?keyword=${value.maBN}`);
      //       console.log(result.data[0]);
      //       if (result.data[0]) {
      //         setInfoUser(result.data[0]);
      //       } else {
      //         error();
      //       }

      const { data } = await axios.get(
        // `https://localhost:7205/api/CRMBenhNhan/GetBenhNhanByMaBN?maBN=${value.maBN}`
        `https://api.315healthcare.com/api/CRMBenhNhan/GetBenhNhanByMaBN?maBN=${value.maBN}`
      );
      // console.log(data?.result[0]); push
      if (data?.result[0]) {
        setInfoUser(data?.result[0]);
      } else {
        error();
      }
    } catch (error) {
      error();
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);

  const showDrawer = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Mã hồ sơ không đúng",
    });
  };
  const formik = useFormik({
    initialValues: {
      maBN: "",
    },
    onSubmit: (value) => handleSubmit(value),
    // validationSchema: traCuuSchema,
  });

  return (
    <>
      {contextHolder}
      <header className={style.header}>
        <nav>
          <ul className={style.ul_nav}>
            {[
              {
                label: <>Điểm lấy máu</>,
                url: "#",
              },
              {
                label: <>Xem kết quả xét nghiệm</>,
                url: "#",
              },
              {
                label: <>Ứng dụng dành cho đối tác</>,
                url: "#",
              },
              {
                label: <>Hỗ trợ</>,
                url: "#",
              },
            ].map((item) => (
              <li className={style.li} key={item.label}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            ))}
            <li className={style.img_responsive}>
              <img src={logo} alt="#" />
            </li>
            <li className={style.phone}>
              <Link to="#">
                <PhoneFilled /> 0908.710.710
              </Link>
            </li>
            <li onClick={showDrawer} className={style.icon_responsive}>
              <UnorderedListOutlined />
            </li>
          </ul>
        </nav>
        <div className={style.container_nav_2}>
          <img src={logo} alt="#" />
          <div className={style.menu}>
            <ul>
              {[
                {
                  label: "Về IVY Health",
                  url: "#",
                },
                {
                  label: "Xét nghiệm",
                  url: "#",
                },
                {
                  label: "Kết quả xét nghiệm",
                  url: "#",
                },
                {
                  label: "Khách hàng",
                  url: "#",
                },
                {
                  label: "Hỗ trợ",
                  url: "#",
                },
              ].map((items) => (
                <li key={items.label}>
                  <Link to={items.url}>{items.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>
      {!infoTraCuu ? (
        <>
          {" "}
          <main>
            <div className={style.main}>
              <h2 className="text-xl font-semibold">Kết Quả Trực Tuyến</h2>
              <p>Dành cho phòng khám, bệnh viện và bệnh nhân</p>
              <p>
                Chào mừng đến với{" "}
                <span style={{ color: "#00afef" }}>
                  Hệ Thống Phòng Khám Đa Khoa Quốc Tế IVY Health.
                </span>{" "}
                Để tra cứu <i>Kết Quả Xét Nghiệm và kết quả khám trực tuyến</i>,
                quý khách vui lòng nhập vào hệ thống.
              </p>
            </div>
            <div className={style.container_form}>
              <form className={style.form}>
                <div className={style.input_container}>
                  <span>Mã hồ sơ</span>
                  <input
                    name="maBN"
                    value={formik.values.maBN}
                    onChange={formik.handleChange}
                    placeholder="Mã hồ sơ"
                  />
                  {formik.errors.maBN && (
                    <span className="text-red-500 text-sm">
                      {formik.errors.maBN}
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  onClick={formik.handleSubmit}
                  className={style.submit}
                >
                  Xem kết quả
                </button>
              </form>
            </div>
          </main>{" "}
        </>
      ) : (
        <div className="flex w-full py-5 justify-center">
          <div className="md:w-1/2 sm:w-4/5 sm:p-0 w-full p-2 ">
            <div className="flex justify-between items-center">
              <div>
                <p className="sm:text-[16px] text-[14px]">
                  <span className="font-semibold ">Tên BN: </span>
                  {infoTraCuu?.tenbn}
                </p>
                <p className="sm:text-[16px] text-[14px]">
                  <span className="font-semibold">Mã BN: </span>{" "}
                  {infoTraCuu?.mabn}
                </p>
              </div>
              <div className="cursor-pointer">
                <button
                  onClick={() => {
                    setInfoUser(null);
                  }}
                  className="text-blue-500 hover:text-blue-400 sm:text-[16px] text-[14px]"
                >
                  Quay lại tra cứu <RollbackOutlined />
                </button>
              </div>
            </div>

            <Tabs
              type="card"
              items={[
                {
                  key: 1,
                  label: "Khám bệnh",
                  children: (
                    <div className="overflow-auto w-full h-fit">
                      {/* <embed
                        src={infoTraCuu?.linK_KQKHAM}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                      /> */}

                      {infoTraCuu?.linK_KQKHAM && (
                        <div
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            height: "600px",
                          }}
                        >
                          <Worker workerUrl=" https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer
                              renderError={() => (
                                <div className="flex justify-center items-center">
                                  <Result
                                    icon={
                                      <a className="flex justify-center text-blue-500" download  href={infoTraCuu?.linK_KQKHAM}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="size-11"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                          />
                                        </svg>
                                      </a>
                                    }
                                    title="Tải file kết quả khám"
                                    // extra={}
                                  />
                                </div>
                              )}
                              fileUrl={infoTraCuu?.linK_KQKHAM}
                              plugins={[newPlugin]}
                            />
                          </Worker>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  key: 2,
                  label: "Xét nghiệm",
                  children: (
                    <div className="overflow-auto w-full h-fit">
                      {/* <embed
                        src={infoTraCuu?.linK_KQXN}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                      /> */}
                      {infoTraCuu?.linK_KQXN && (
                        <div
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            height: "600px",
                          }}
                        >
                          <Worker workerUrl=" https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer
                            renderError={() => (
                              <div className="flex justify-center items-center">
                                <Result
                                  icon={
                                    <a className="flex justify-center text-blue-500" download  href={infoTraCuu?.linK_KQXN}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-11"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                      </svg>
                                    </a>
                                  }
                                  title="Tải file kết quả xet nghiệm"
                                  // extra={}
                                />
                              </div>
                            )}
                              fileUrl={infoTraCuu?.linK_KQXN}
                              plugins={[newPlugin]}
                            />
                          </Worker>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  key: 3,
                  label: "Chẩn đoán hình ảnh",
                  children: (
                    <div className="overflow-auto w-full h-fit">
                      {/* <embed
                        src={infoTraCuu?.linK_KQXN}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                      /> */}
                      {infoTraCuu?.linK_KQCDHA && (
                        <div
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            height: "600px",
                          }}
                        >
                          <a href="">Tải kết quả</a>
                          <Worker workerUrl=" https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                            <Viewer
                             renderError={() => (
                              <div className="flex justify-center items-center">
                                <Result
                                  icon={
                                    <a className="flex justify-center text-blue-500" download  href={infoTraCuu?.linK_KQCDHA}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-11"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                      </svg>
                                    </a>
                                  }
                                  title="Tải file kết quả chẩn đoán hình ảnh"
                                  // extra={}
                                />
                              </div>
                            )}
                              fileUrl={infoTraCuu?.linK_KQCDHA}
                              plugins={[newPlugin]}
                            />
                          </Worker>
                        </div>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      )}
      <Drawer
        title={<img className="w-36" src={logo} alt="#" />}
        onClose={onClose}
        open={show}
      >
        <Menu
          style={{}}
          // defaultSelectedKeys={['1']}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          items={[
            {
              key: 1,
              label: (
                <h2 className="font-semibold text-lg text-blue-500">
                  Xem kết quả xét nghiệm
                </h2>
              ),
            },
            {
              key: 2,
              label: (
                <h2 className="font-semibold text-lg text-blue-500">
                  Điểm lấy máu
                </h2>
              ),
            },
            {
              key: 3,
              label: (
                <h2 className="font-semibold text-lg text-blue-500">
                  ứng dụng dành cho đối tác
                </h2>
              ),
            },
            {
              type: "divider",
            },
            {
              key: 4,
              label: <h2 className="font-semibold text-lg ">về IVY Health</h2>,
            },
            {
              key: 5,
              label: <h2 className="font-semibold text-lg ">Xét nghiệm</h2>,
            },
            {
              key: 6,
              label: <h2 className="font-semibold text-lg ">Khách hàng</h2>,
            },
            {
              key: 7,
              label: <h2 className="font-semibold text-lg ">Hổ trợ</h2>,
            },
          ]}
        />
      </Drawer>
      <div className="fixed bottom-8 right-4 md:bottom-12 md:right-8">
        <FloatButton
          shape="circle"
          type="primary"
          icon={<CustomerServiceOutlined />}
          className="w-12 h-12 md:w-16 md:h-16"
        />
      </div>
    </>
  );
};

export default Home;
